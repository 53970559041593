import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractAuditReviewService } from '../abstraction/abstract.audit-review.service';
import { ITableServerResponseFilter } from '../../../common/interfaces/table/table-server-filter-response.interface';
import { ITableServerFilter } from '../../../common/interfaces/table/table-server-filter.inteface';

@Injectable({
  providedIn: 'root',
})
export class AuditReviewService extends AbstractAuditReviewService {
  tableFilter(filter: ITableServerFilter): Promise<ITableServerResponseFilter | undefined> {
    throw new Error('Method not implemented.');
  }
  tableExportCSV(filter: ITableServerFilter): Promise<Blob | undefined> {
    throw new Error('Method not implemented.');
  }
  tableExportExcel(filter: ITableServerFilter): Promise<any> {
    throw new Error('Method not implemented.');
  }
}
