import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ITableServerResponseFilter } from 'src/app/common/interfaces/table/table-server-filter-response.interface';
import { ITableServerFilter } from 'src/app/common/interfaces/table/table-server-filter.inteface';
import { AbstractConfigurationPendingChangesService } from '../abstraction/abstract.configuration-pending-changes.service';
import { environment } from 'src/environments/environment';
import { IDualControlDetail } from '../../interfaces/configuration/dual-control-detail.interface';
import { IWebMessage } from 'src/app/common/interfaces/webmessage-interface';
import { IDualControlDecision } from '../../interfaces/configuration/dual-control-decision.interface';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationPendingChangesService extends AbstractConfigurationPendingChangesService {
  controller = `/manager/dualcontrolpendingreview`;
  urlGetGridList = `${environment.apiUrl}${this.controller}/grid`;
  urlGetGridExportCSV = `${environment.apiUrl}${this.controller}/gridExportCSV`;
  urlGetGridExportExcel = `${environment.apiUrl}${this.controller}/gridExportExcel`;
  urlGetDetail = `${environment.apiUrl}${this.controller}/detail`;
  urlGetCount = `${environment.apiUrl}${this.controller}/count`;
  urlPostValidate = `${environment.apiUrl}${this.controller}/validate`;
  urlPostApprove = `${environment.apiUrl}${this.controller}/approve`;
  urlPostReject = `${environment.apiUrl}${this.controller}/reject`;

  constructor(private http: HttpClient) {
    super();
  }

  tableFilter(filter: ITableServerFilter): Promise<ITableServerResponseFilter | undefined> {
    return this.http.post<ITableServerResponseFilter>(`${this.urlGetGridList}`, filter).toPromise();
  }

  tableExportCSV(filter: ITableServerFilter): Promise<Blob | undefined> {
    return this.http
      .post(`${this.urlGetGridExportCSV}`, filter, {
        responseType: 'blob',
      })
      .toPromise();
  }

  tableExportExcel(filter: ITableServerFilter): Promise<any | undefined> {
    return this.http.post(`${this.urlGetGridExportExcel}`, filter).toPromise();
  }

  getDetail(id: number): Promise<IDualControlDetail | undefined> {
    return this.http.get<IDualControlDetail>(`${this.urlGetDetail}/${id}`).toPromise();
  }

  getCount(): Promise<number | undefined> {
    const headers = new HttpHeaders().set('Skip-Loading', 'true');
    return this.http.get<number>(`${this.urlGetCount}`, { headers }).toPromise();
  }

  postValidate(decision: IDualControlDecision): Promise<IWebMessage | undefined> {
    return this.http.post<IWebMessage>(`${this.urlPostValidate}`, decision).toPromise();
  }

  postReject(decision: IDualControlDecision): Promise<IWebMessage | undefined> {
    return this.http.post<IWebMessage>(`${this.urlPostReject}`, decision).toPromise();
  }

  postApprove(decision: IDualControlDecision): Promise<IWebMessage | undefined> {
    return this.http.post<IWebMessage>(`${this.urlPostApprove}`, decision).toPromise();
  }
}
